@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700");

/**************** COLORS *******************/

$blue: rgb(15, 185, 236);
// $primary: #002642;
$primary: rgb(0, 38, 66);
// $secondary: #a2a3bb;
$secondary: rgb(108, 179, 230);
$dark: rgb(22, 103, 161);
$ligth: rgb(51, 145, 212);
$ligther: rgb(58, 153, 222);
$white: #fbf9ff;

$red: rgb(207, 33, 33);
$dark-red: rgb(136, 25, 25);

$green: rgb(25, 197, 19);
$dark-green: rgb(14, 119, 10);

/********************* FONTS ****************/

$body-font: "Cairo", sans-serif;
$h1-font-size: 1.7rem;
$h2-font-size: 1.3rem;
$h3-font-size: 1.1rem;
$h4-font-size: 1rem;

/******************** SPACING ****************/

$spacing-1: 0.4rem;
$spacing-2: 0.8rem;
$spacing-3: 1.2rem;
$spacing-4: 1.5rem;
$spacing-5: 2rem;

/********************* PLACEHOLDER ********************/

%flex-container {
  display: flex;
  min-height: calc(100vh - 100px - 4rem);
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

/*********************** RESET **********************/

*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

body {
  margin: 0;
  font-family: $body-font;
  font-weight: 300;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white;
  color: $primary;
  letter-spacing: 0.05rem;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
  &:visited {
    color: inherit;
  }
}

p.error {
  color: $red;
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  font-style: italic;
}

.hide {
  display: none;
}

.button {
  padding: 0.9rem;
  margin: 2rem 0 0 0;
  display: block;
  color: $white;
  font-weight: 600;
  background-color: $primary;
  border: solid 1px $primary;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: rgba($primary, 0.8);
    color: $white;
  }
  &.danger {
    border-color: $red;
    background-color: $red;
    color: $white;
    &:hover {
      border-color: $dark-red;
      background-color: $dark-red;
      color: $white;
    }
  }
}

/*********************  COLOR  *******************/
.success {
  color: $green;
}

.danger {
  color: $red;
}

.info {
  color: $ligth;
}

/********************  ALERT  ********************/

.alert {
  display: block;
  width: 100%;
  margin: 0.8rem 0;
  padding: 0.5rem 0.8rem;
  font-weight: 600;

  &__error {
    background-color: rgba($red, 0.8);
    color: $white;
    border-radius: 5px;
  }
}

/*********************** APP *********************/

.App {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.main {
  width: 100%;
  height: 100%;

  &__title {
    font-size: $h1-font-size;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    @media screen and (max-width: 350px) {
      font-size: $h2-font-size;
    }
  }
}

/************************* FORM *******************/

.form {
  position: relative;
  width: 50%;
  border: solid 1px $primary;
  border-radius: 15px;
  padding: 2rem;
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  h2 {
    position: absolute;
    text-transform: uppercase;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: $h2-font-size;
    margin: 0;
    padding: 1rem;
    background-color: $white;
    font-weight: 600;
    text-align: center;
  }

  &__group {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  &__label {
    display: block;
    font-weight: 600;
  }

  &__label--radio {
    margin-left: -0.2rem;
  }

  & input[type="text"],
  & input[type="password"],
  & select {
    padding: 0.7rem;
    width: 100%;
    display: block;
    background-color: $white;
    border: solid 1px $primary;
    border-radius: 5px;
    &.error {
      border-color: $red;
    }
  }

  & input[type="radio"] {
    margin: 0 0.8rem;
  }

  & input[type="checkbox"] {
    margin: 0 0.8rem 0 0;
  }

  button {
    padding: 0.9rem;
    margin: 2rem 0 0 0;
    display: block;
    color: $white;
    font-weight: 600;
    background-color: $primary;
    border: solid 1px $primary;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: rgba($primary, 0.8);
    }
  }
}

/************************ SIDEBAR *********************/

.sidebar {
  &.hide-nav {
    position: fixed;
    visibility: hidden;
    left: -100vw;
  }

  &__links {
    position: fixed;
    z-index: 10;
    left: -100vw;
    bottom: 0;
    min-height: 100vh;
    height: 100%;
    min-width: 30%;
    visibility: hidden;
    background-color: $primary;
    color: $white;
    padding-top: 60px;
    box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.35);
    transition: 0.8s ease-in;
    @media screen and (max-width: 768px) {
      width: 100vw;
    }

    &.show-links {
      left: 0;
      visibility: visible;
      min-width: 30%;
      overflow: auto;
      transition: 0.8s ease-in;
      padding-top: 60px;
      @media screen and (max-width: 768px) {
        width: 100vw;
      }
    }
  }

  &__title {
    font-size: $h1-font-size;
    text-transform: uppercase;
    margin: $spacing-1 $spacing-1 $spacing-2 $spacing-2;
    font-weight: 600;
    letter-spacing: 0.02rem;
    color: rgba($secondary, 0.7);
    @media screen and (min-width: 767.99px) and (max-width: 1000px) {
      font-size: $h2-font-size;
    }
  }

  &__subtitle {
    font-size: $h3-font-size;
    text-transform: uppercase;
    font-weight: 600;
    margin: $spacing-4 $spacing-1 $spacing-2 $spacing-2;
    color: $secondary;
  }

  &__menu {
    padding: $spacing-1 $spacing-3;
    background-color: rgba($primary, 0.1);
    border-bottom: solid 1px rgba($dark, 0.4);
    font-weight: 400;
    display: block;
    width: 100%;
    &:first-child {
      border-top: solid 1px rgba($dark, 0.4);
    }
    &:hover {
      background-color: $dark;
      cursor: pointer;
      transition: 0.3s ease-in;
    }
    &:active {
      background-color: $ligther;
    }

    &.active {
      background-color: $dark;
    }
  }
  &__burger {
    z-index: 100;
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    color: inherit;
    display: block;
    position: fixed;
    top: 1rem;
    left: 1rem;

    &:hover {
      cursor: pointer;
    }

    .burger-bar,
    .burger-bar::before,
    .burger-bar::after {
      display: block;
      width: 40px;
      height: 3px;
      position: relative;
      border-radius: 3px;
      background: $white;
      transition: all 0.5s ease-in-out;
    }

    .burger-bar {
      &::before {
        content: "";
        position: absolute;
        left: 0;
        transform: translateY(-12px);
      }
      &::after {
        content: "";
        position: absolute;
        left: 0;
        transform: translateY(12px);
      }
    }

    .expand {
      &.burger-bar {
        width: 0;
        background: transparent;
        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }
}

/********************* TOPBAR ******************/

.topbar {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100px;
  background-color: $primary;
  color: $ligther;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 8px 0px rgba(0, 38, 66, 0.35);
  -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 38, 66, 0.35);
  -moz-box-shadow: 0px 4px 8px 0px rgba(0, 38, 66, 0.35);
  @media screen and (max-width: 500px) {
    justify-content: flex-end;
    align-items: center;
    padding: 0 0.5rem 0 0rem;
    height: 80px;
  }

  &__prev,
  &__next {
    display: flex;
    align-items: center;
    & svg {
      font-size: 2.5rem;
      &:hover {
        font-size: 3.3rem;
        transition: 0.15s ease-in-out;
        cursor: pointer;
        color: $secondary;
      }
    }
    &.disabled {
      & svg {
        color: rgba($white, 0.1);
        &:hover {
          font-size: 2.5rem;
          cursor: not-allowed;
          color: rgba($white, 0.1);
        }
      }
    }
  }

  &__month {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    font-size: $h2-font-size;
    font-weight: 600;
    margin: 0 1.5rem;
    color: $secondary;
    @media screen and (max-width: 300px) {
      margin: 0;
    }
  }
}

/********************* HOME PAGE **********************/

.home {
  @extend %flex-container;
}

/********************* BANK SUMMARY ********************/

.month-overview {
  @media screen and (max-width: 768px) {
    padding: 0;
  }
}

.summary {
  width: 100%;
  padding: 1.5rem;
  @media screen and (min-width: 769px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.5rem;
    margin-bottom: 2rem;
  }

  h4 {
    text-transform: uppercase;
    font-size: $h4-font-size;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    font-weight: 700;
  }

  p {
    text-align: right;
    font-weight: 400;
  }

  &__revenus {
    color: green;
  }
  &__depenses {
    color: crimson;
  }
  &__balance {
    color: mediumblue;
  }
  &__today {
    color: purple;
  }
  &__endmonth {
    color: peru;
  }
  &__beginmonth {
    color: #bd9751;
  }
}

.table-header {
  display: grid;
  grid-template-columns: 2fr 3fr 2fr 2fr 1fr;
  align-items: center;
  text-transform: uppercase;
  font-weight: 700;
  background-color: $secondary;
  border-top: solid 2px $primary;
  border-bottom: solid 2px $primary;
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }

  &__date {
    padding: 0.5rem 1rem;
    @media screen and (max-width: 768px) {
      padding: 0.4rem 0.2rem;
    }
  }

  &__libelle {
    padding: 0.5rem 1rem;
    @media screen and (max-width: 768px) {
      padding: 0.4rem 0.2rem;
    }
  }

  &__amount {
    text-align: right;
    padding: 0.5rem 1rem;
    @media screen and (max-width: 768px) {
      padding: 0.4rem 0.2rem;
    }
  }
}

.table-content {
  display: grid;
  grid-template-columns: 2fr 3fr 2fr 2fr 1fr;
  align-items: center;
  border-bottom: solid 1px $primary;
  font-weight: 600;
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }

  &:nth-child(even) {
    background-color: rgba($secondary, 0.1);
  }

  &:nth-child(1n + 0):hover {
    font-weight: 700;
    background-color: rgba($secondary, 0.3);
    transition: 0.15s ease-in;
  }

  &__date {
    padding: 0.5rem 1rem;
    @media screen and (max-width: 768px) {
      padding: 0.4rem 0.2rem;
    }
  }

  &__libelle {
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    @media screen and (max-width: 768px) {
      padding: 0.4rem 0.2rem;
    }
  }

  &__amount {
    text-align: right;
    padding: 0.5rem 1rem;
    @media screen and (max-width: 768px) {
      padding: 0.4rem 0.2rem;
    }
  }

  &__controls {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0.3rem;
    @media screen and (max-width: 768px) {
      padding-right: 0;
    }
    & svg {
      font-size: 1.5rem;
      @media screen and (max-width: 768px) {
        font-size: 0.8rem;
      }
    }
  }
}

/********************** MOVEMENT *************/

.movement {
  @extend %flex-container;
}

/********************** MODAL *************/

.modal-container {
  @extend %flex-container;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  // min-height: 100vh;
  // height: 100%;
  background: rgba(0, 38, 66, 0.8);
  z-index: 1000;
  margin: 0;

  &__modal {
    background: $white;
    width: 50%;
    padding: 2rem;
    text-align: center;
    border-radius: 1rem;
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.75);
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    h3 {
      font-size: $h3-font-size;
    }
    p {
      margin: 1rem 0 2rem 0;
      font-size: 1.5rem;
    }
    .button-container {
      position: relative;
      width: 100%;
      height: 50px;
      margin: 0;
      padding: 0;

      .button__left {
        position: absolute;
        top: 0;
        left: 0;
        height: 50px;
      }

      .button__right {
        position: absolute;
        top: 0;
        right: 0;
        height: 50px;
      }
    }
    button {
      margin: 0 auto;
    }
  }
}

/********************** GESTION *************/
.gestion {
  @extend %flex-container;
  z-index: 100;
  background: transparent;
  border: none;
  color: $white;
  position: fixed;
  top: 0rem;
  right: 2rem;
  text-align: right;
  height: 100px;
  min-height: 100px;
  margin: 0;

  svg {
    width: 35px;
    height: 35px;
  }

  @media screen and (max-width: 767px) {
    visibility: hidden;
    display: none;
  }
}
